
import React from "react";
import {
  BrowserRouter as Router,
  // unstable_HistoryRouter as HistoryRouter,
  Routes,
  Route,
} from "react-router-dom";

import { createBrowserHistory } from "history";

import {
  Home,
  About,
  Blog,
  BlogDetails,
  Contact,
  Quote,
  Services,
  PrivacyPolicy,
  ServiceDetails,
  Portfolio,
  Internship,
  NotFound,
  AffiliatePage,
  EbiorePolicy,
} from "../pages";

import * as ROUTES from "../constants/routes";
import StaticRoute from "./StaticRoute";
import PolicyPermissionModal from "../components/PrivacyPolicy/PolicyPermissionModal";

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <>
      <PolicyPermissionModal />
      <Routes>
        <Route element={<StaticRoute />}>
          <Route element={<Home />} path={ROUTES.HOME} />
          <Route element={<About />} path={ROUTES.ABOUT} />
          {/* <Route
						element={<Blog />}
						path={ROUTES.BLOG}
					/>
					<Route
						element={<BlogDetails />}
						path={`${ROUTES.BLOG_DETAILS}/:slug`}
					/> */}
          <Route element={<Services />} path={ROUTES.SERVICES} />
          <Route element={<PrivacyPolicy />} path={ROUTES.PRIVACY_POLICY} />
          <Route element={<Portfolio />} path={ROUTES.PORTFOLIO} />
          <Route
            element={<ServiceDetails />}
            path={`${ROUTES.SERVICE_DETAILS}/:slug`}
          />
          <Route element={<Quote />} path={ROUTES.QUOTE} />
          <Route element={<Contact />} path={ROUTES.CONTACT} />
          <Route element={<Internship />} path={ROUTES.INTERNSHIP} />
          <Route element={<NotFound />} path={ROUTES.NOTFOUND} />
          <Route element={<AffiliatePage />} path={ROUTES.AFFILIATE} />
        </Route>
        <Route element={<EbiorePolicy />} path={ROUTES.EBIOREPOLICY} />
      </Routes>
    </>
  </Router>
);

export default AppRouter;
 