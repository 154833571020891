
import React from 'react';
import { useDocumentTitle, useScrollTop } from '../../../hooks'
import Banner from '../../../components/Banner/Banner'
import PolicyHeader from '../../../components/PrivacyPolicy/PolicyHeader'
import Definitions from '../../../components/PrivacyPolicy/Definitions'
import DataCollection from '../../../components/PrivacyPolicy/DataCollection'
import ChildrenPrivacy from '../../../components/PrivacyPolicy/ChildrenPrivacy'
import Links from '../../../components/PrivacyPolicy/Links'
import Changes from '../../../components/PrivacyPolicy/Changes'
import ContactUs from '../../../components/PrivacyPolicy/ContactUs'


const PrivacyPolicy = () => {
  useDocumentTitle("Privacy Policy | Luday AB");
  useScrollTop();
  return (
    <div className="app">
      <Banner titleText="Our Privacy Policy" subtitleText="Privacy Policy" />
      <section className="bg-white">
        <div className="max-w-7xl px-6 py-5 mx-auto flex flex-col gap-8">
          <PolicyHeader />
          <Definitions />
          <DataCollection />
          <ChildrenPrivacy />
          <Links />
          <Changes />
          <ContactUs />
        </div>
      </section>
    </div>
  );
}
export default PrivacyPolicy;
 