import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const policyFreePages = ["/privacy-policy", "/contact"];

const PolicyPermissionModal = () => {
  const location = useLocation();
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const shouldShowPolicy =
    !policyFreePages.includes(location.pathname) && !isPolicyAccepted;

  useEffect(() => {
    const policyAccepted =
      localStorage.getItem("privacyPolicyAccepted") === "true";
    setIsPolicyAccepted(policyAccepted);
  }, [location]);

  const handleAcceptPolicy = () => {
    setIsPolicyAccepted(true);
    localStorage.setItem("privacyPolicyAccepted", "true");
  };

  return (
    <>
      {shouldShowPolicy && (
        <div className=" fixed z-50 top-0 left-0 flex h-full w-full justify-center items-center bg-black/95">
          <div className=" bg-white p-5 rounded-md w-full md:w-[30%] flex flex-col gap-2 mx-5 py-8">
            <p className=" font-bold text-md text-center">Policy updates</p>
            <p className=" text-xs text-center">
              We,ve updated our{" "}
              <Link className=" text-blue-600 font-bold" to="/privacy-policy">
                Privacy Policy
              </Link>
              . It's very important to us that you take a moment to review these
              details to ensure we’re aligned on how we use your personal data.
            </p>
            <p className=" text-xs text-center">
              Once you have read our updated terms and agree with them, please
              click <b>"Accept"</b>. If you have questions regarding this
              privacy policy,{" "}
              <Link className=" text-blue-600 font-bold" to="/contact">
                Contact Us
              </Link>
            </p>
            <div className=" w-full flex justify-center mt-2">
              <button
                onClick={handleAcceptPolicy}
                className="bg-[#3BA4E9] flex gap-2 py-2 px-8 w-max rounded-md text-white text-xs font-bold"
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PolicyPermissionModal;
