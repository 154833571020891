
import React, { useEffect } from "react";
/* This example requires Tailwind CSS v3.0+ */
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink, useLocation } from "react-router-dom";

import logo from "../../../../images/luday_logo.png";
import * as ROUTE from "../../../../constants/routes";

const Navigation = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const location = useLocation();

  const [scrolling, setScrolling] = useState(false);
  const [showInternDropdown, setShowInternDropdown] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
        setShowInternDropdown(false)
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`fixed w-full z-10 top-0 ${scrolling
        ? `bg-white shadow-md`
        : `${location.pathname !== "/" ? "bg-white" : "bg-transparent"}`
        } transition-colors duration-300 ease-in-out`}
    >
      <div className="px-6 py-0 lg:py-4 lg:px-8">
        <nav className="flex items-center justify-between" aria-label="Global">
          <div className="flex">
            <NavLink to={ROUTE.HOME} className="-m-1.5 p-1.5">
              <img
                className="w-30 bg-[url('images/luday_logo_line.png')] hover:bg-none"
                src={logo}
                alt="logo"
              />
            </NavLink>
          </div>

          <div className="flex lg:hidden items-center gap-5">
            <div className="gtranslate_wrapper z-20"></div>

            <button
              type="button"
              className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 ${scrolling ? "text-gray-700" : `${location.pathname !== "/" ? "text-gray-900" : "text-white"
                }`
                }`}
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="hidden lg:flex gap-12">
            <NavLink
              to={ROUTE.HOME}
              className={`text-lg font-semibold leading-6 ${scrolling ? "text-gray-900" : `${location.pathname !== "/" ? "text-gray-900" : "text-white"
                }`
                }`}
            >
              Home
            </NavLink>
            <div className="flex items-center gap-1">
              <NavLink
                to={ROUTE.ABOUT}
                className={`text-lg font-semibold leading-6 ${scrolling ? "text-gray-900" : `${location.pathname !== "/" ? "text-gray-900" : "text-white"
                  }`
                  }`}
              >
                About Us
              </NavLink>
              <button onClick={() => setShowInternDropdown(!showInternDropdown)}>
                <svg xmlns="http://www.w3.org/2000/svg" className={`${location.pathname === '/' && !scrolling ? 'text-white' : 'text-black'}`} width="20" height="20" viewBox="0 0 24 24"><g id="feArrowDown0" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1"><g id="feArrowDown1" fill="currentColor"><path id="feArrowDown2" d="m6 7l6 6l6-6l2 2l-8 8l-8-8z" /></g></g></svg>
              </button>

              {showInternDropdown && (
                <div className="absolute top-[5.5rem] w-[15%] h-fit flex items-center justify-center bg-white rounded-md" onMouseLeave={() => setShowInternDropdown(false)}>
                  <div className="w-full flex flex-col gap-3 p-6">
                    <NavLink
                      to={ROUTE.ABOUT}
                      className={`text-lg font-semibold leading-6 ${scrolling ? "text-gray-900" : `${location.pathname !== "/about/internship" ? "text-gray-900" : "text-black"
                        }`
                        }`}
                    >
                      About
                    </NavLink>
                    <a href="/#clients" className={`text-lg font-semibold leading-6 ${scrolling ? "text-gray-900" : `${location.pathname !== "/about/internship" ? "text-gray-900" : "text-black"}`}`}>
                        Clients
                    </a>
                    <a href="/services/#portfolio" className={`text-lg font-semibold leading-6 ${scrolling ? "text-gray-900" : `${location.pathname !== "/about/internship" ? "text-gray-900" : "text-black"}`}`}>
                        Work Samples
                    </a>
                    <NavLink
                      to={ROUTE.INTERNSHIP}
                      className={`text-lg font-semibold leading-6 ${scrolling ? "text-gray-900" : `${location.pathname !== "/about/internship" ? "text-gray-900" : "text-black"
                        }`
                        }`}
                    >
                      Internship
                    </NavLink>
                  </div>
                </div>)
              }
            </div>

            <NavLink
              to={ROUTE.SERVICES}
              className={`text-lg font-semibold leading-6 ${scrolling ? "text-gray-900" : `${location.pathname !== "/" ? "text-gray-900" : "text-white"
                }`
                }`}
            >
              Services
            </NavLink>
            <NavLink
              to={ROUTE.AFFILIATE}
              className={`text-lg font-semibold leading-6 ${scrolling ? "text-gray-900" : `${location.pathname !== "/" ? "text-gray-900" : "text-white"
                }`
                }`}
            >
              Affiliate
            </NavLink>
            {/* <NavLink
              to={ROUTE.BLOG}
              className={`text-lg font-semibold leading-6 ${scrolling ? "text-gray-900" : `${location.pathname !== "/" ? "text-gray-900" : "text-white"
                }`
                }`}
            >
              Blogs
            </NavLink> */}
            <NavLink
              to={ROUTE.PRIVACY_POLICY}
              className={`text-lg font-semibold leading-6 ${
                scrolling
                  ? "text-gray-900"
                  : `${
                      location.pathname !== "/" ? "text-gray-900" : "text-white"
                    }`
              }`}
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to={ROUTE.CONTACT}
              className={`text-lg font-semibold leading-6 ${scrolling ? "text-gray-900" : `${location.pathname !== "/" ? "text-gray-900" : "text-white"
                }`
                }`}
            >
              Contact Us
            </NavLink>
          </div>

          <div className="hidden lg:flex gap-10 items-center lg:justify-end">
            <div className="gtranslate_wrapper z-20"></div>
            <button className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#3BA4E9] rounded-lg hover:bg-[#01015D] focus:outline-none focus:ring focus:ring-[#01015D] focus:ring-opacity-80">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>

              <NavLink to={ROUTE.QUOTE} className="mx-1">
                Get a Quote
              </NavLink>
            </button>
          </div>
        </nav>
        <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Dialog.Panel
            focus="true"
            className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden"
          >
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="w-30 bg-[url('images/luday_logo_line.png')] hover:bg-none"
                  src={logo}
                  alt="Luday AB logo"
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <NavLink
                    to={ROUTE.HOME}
                    className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to={ROUTE.ABOUT}
                    className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    About us
                  </NavLink>
                  <NavLink
                    to={ROUTE.SERVICES}
                    className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Services
                  </NavLink>
                  <NavLink
                    to={ROUTE.AFFILIATE}
                    className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Affiliate
                  </NavLink>
                  {/*<NavLink
                    to={ROUTE.BLOG}
                    className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Blogs
                  </NavLink>*/}
                  <NavLink
                    to={ROUTE.CONTACT}
                    className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Contact us
                  </NavLink>
                </div>
                <div className="py-6">
                  <button className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#3BA4E9] rounded-lg hover:bg-[#01015D] focus:outline-none focus:ring focus:ring-blue-[#01015D] focus:ring-opacity-80">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                      />
                    </svg>

                    <NavLink
                      to={ROUTE.QUOTE}
                      className="mx-1"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Get a Quote
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </div>
  );
};

export default Navigation;
 