
import React from "react";

const terms = [
  {
    term: "Account",
    definition:
      "A unique account created for You to access our Service or parts of our Service.",
  },
  {
    term: "Affiliate",
    definition:
      "An entity that controls, is controlled by or is under common control with a party, where 'control' means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.",
  },
  {
    term: "Company",
    definition:
      "Refers to Luday AB, Gothenburg, Sweden. Referred to as either 'the Company', 'We', 'Us' or 'Our' in this Agreement.",
  },
  {
    term: "Cookies",
    definition:
      "Small files placed on Your computer, mobile device or any other device by a website, containing details of Your browsing history on that website among its many uses.",
  },
  {
    term: "Country",
    definition: "Refers to the country you access the service from.",
  },
  {
    term: "Device",
    definition:
      "Any device that can access the Service such as a computer, a cellphone or a digital tablet.",
  },
  {
    term: "Personal Data",
    definition:
      "Any information that relates to an identified or identifiable individual.",
  },
  {
    term: "Service",
    definition: "Refers to the Website.",
  },
  {
    term: "Service Provider",
    definition:
      "Any natural or legal person who processes the data on behalf of the Company. Refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service, or to assist the Company in analyzing how the Service is used.",
  },
  {
    term: "Third-party Social Media Service",
    definition:
      "Any website or social network website through which a User can log in or create an account to use the Service.",
  },
  {
    term: "Usage Data",
    definition:
      "Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).",
  },
  {
    term: "Website",
    definition: "Refers to LudayPro, accessible from https://ludaypro.se.",
  },
  {
    term: "You",
    definition:
      "The individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
  },
];

const Definitions = () => {
  return (
    <div className=" flex flex-col gap-2 mt-5" id="definitions">
      <h2 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
        Interpretation and Definitions
      </h2>
      <h2 className="mt-2 text-lg font-bold tracking-tight text-gray-900">
        Interpretation
      </h2>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h2 className="mt-2 text-lg font-bold tracking-tight text-gray-900">
        Definitions
      </h2>
      <p>For the purposes of this Privacy Policy:</p>
      <ul className=" px-5 space-y-2">
        {terms.map((term) => {
          return (
            <li className=" list-disc">
              <b>{term.term}</b>: {term.definition}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Definitions;
 