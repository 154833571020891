
import React from "react";

const Links = () => {
  return (
    <div className=" flex flex-col gap-2 mt-5" id="links">
      <h2 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
        Links to Other Websites
      </h2>
      <p>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
    </div>
  );
};

export default Links;

 