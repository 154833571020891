
import React from "react";

const PolicyHeader = () => {
  return (
    <div className=" flex flex-col gap-5 mt-5">
      <div className=" grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className=" flex flex-col gap-6">
          <h2 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
            This <b className="text-blue-600">Privacy Policy</b> describes:
          </h2>
          <div className="flex flex-col px-1 gap-2">
            <p>
              Our policies and procedures on the collection, use and disclosure
              of Your information when You use the Service and tells You about
              Your privacy rights and how the law protects You.
            </p>
            <p>
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy.
            </p>
          </div>
        </div>
        <div className=" flex min-w-full justify-center">
          <div className="flex flex-col gap-5 shadow-md p-5 border border-gray-200 w-full md:w-max md:min-w-[70%]">
            <p className=" font-semibold border-b pb-2">Table of Contents:</p>
            <div className=" flex flex-col gap-2">
              <a
                href="#definitions"
                className=" text-xs text-blue-600 hover:font-bold active:font-bold"
              >
                Interpretation and Definitions
              </a>
              <a
                href="#data"
                className=" text-xs text-blue-600 hover:font-bold active:font-bold"
              >
                Collecting and Using Your Personal Data
              </a>
              <a
                href="#children"
                className=" text-xs text-blue-600 hover:font-bold active:font-bold"
              >
                Children's Privacy
              </a>
              <a
                href="#links"
                className=" text-xs text-blue-600 hover:font-bold active:font-bold"
              >
                Links to Other Websites
              </a>
              <a
                href="#changes"
                className=" text-xs text-blue-600 hover:font-bold active:font-bold"
              >
                Changes to this Privacy Policy
              </a>
              <a
                href="#contact"
                className=" text-xs text-blue-600 hover:font-bold active:font-bold"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyHeader;
 