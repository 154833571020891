
import React from 'react'
import logo from '../../../images/luday_logo_w.png';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import * as ROUTE from '../../../constants/routes'
import swal from 'sweetalert';

const Footer = () => {

  const {register, handleSubmit, reset, formState: { errors } } = useForm();

	const swalAlert = (status, text, title) => {
		return(
      swal({
        title: title,
        text: text,
        icon: status
      })            
		)
	}

	const submitForm = (data) => {
		const body = {
		  email: data.email
		}

		const requestOptions = {
			method: "POST",
			headers: {
			  'content-type': 'application/json'
			},
			body: JSON.stringify(body)
		}

		fetch(`${ROUTE.CONTACT_API}/newsletters/add`, requestOptions)
		.then(res => res.json())
		.then(data =>{
      if (data?.messages?.json?.email[0] === 'Email already subscribed.') {
          swalAlert('error', 'Email already subscribed.', 'Error!')
      } else {
          swalAlert('success', 'Email subscribed', 'Info!')
      }
		})
		.catch(err => console.log(err))

		reset()
	}

  return (
    <>     
       <footer className="bg-gray bg-gray-900 pt-10">
          <div className="container p-6 max-w-7xl mx-auto bg-gray">
              <div className="lg:flex">
                  <div className="w-full -mx-6 lg:w-2/5">
                      <div className="px-6">
                          <Link to={ROUTE.HOME}>
                              <img className="w-30 bg-[url('images/luday_logo_line.png')] hover:bg-none" src={logo} alt=""/>
                          </Link>

                          <p className="max-w-sm mt-2 text-gray-500 dark:text-gray-400">
                            Luday AB<br/>
                            Theres Svenssons gata 13,<br/>
                            41755 Gothenburg Sweden.
                          </p>
                          <p className="max-w-sm mt-2 text-gray-500 dark:text-gray-400">
                            Phone: +46735759495
                          </p>

                          <div className="flex mt-6 -mx-2">
                              <a href="https://www.linkedin.com/company/luday/"
                                  className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                  aria-label="Linkedin">
                                  <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px">
                                    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"/>
                                  </svg>
                              </a>

                              <a href="https://twitter.com/ludaysocial"
                                  className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                  aria-label="X">
                                  <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
                                    <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"/>
                                  </svg>
                              </a>

                              <a href="https://www.facebook.com/ludaysocial"
                                  className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                  aria-label="Facebook">
                                  <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px">    
                                    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"/>
                                  </svg>
                              </a>

                              <a href="https://www.instagram.com/ludayx/"
                                  className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                  aria-label="Instagram">
                                  <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px">
                                    <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"/>
                                  </svg>
                              </a>
                          </div>
                      </div>
                  </div>

                  <div className="mt-6 lg:mt-0 lg:flex-1">
                      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                            <div>
                                <h3 className="text-gray-700 uppercase font-semibold dark:text-white">Useful Links</h3>
                                <Link to={ROUTE.ABOUT} className="block mt-2 text-sm text-gray-400 hover:underline">Company</Link>
                                <Link to={ROUTE.SERVICES} className="block mt-2 text-sm text-gray-400 hover:underline">Services</Link>                       
                                {/* <Link to={ROUTE.BLOG} className="block mt-2 text-sm text-gray-400 hover:underline">Blogs</Link> */}
                                <Link to={ROUTE.CONTACT} className="block mt-2 text-sm text-gray-400 hover:underline">Contact Us</Link>
                            </div>

                            <div>
                                <h3 className="text-gray-700 uppercase font-semibold dark:text-white">Our Services</h3>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/web-development`} className="block mt-2 text-sm text-gray-400 hover:underline">Web Development</Link>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/embedded-software-development`} className="block mt-2 text-sm text-gray-400 hover:underline">Embedded Software Development</Link>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/digital-marketing`} className="block mt-2 text-sm text-gray-400 hover:underline">Digital Marketing</Link>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/ui-ux-design`} className="block mt-2 text-sm text-gray-400 hover:underline">UI/UX Design</Link>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/product-management`} className="block mt-2 text-sm text-gray-400 hover:underline">Product Management</Link>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/data-analytics`} className="block mt-2 text-sm text-gray-400 hover:underline">Data Analytics</Link>
                            </div>
                            <form>
                                <div>
                                    <h3 className="text-gray-700 uppercase font-semibold dark:text-white">Newsletter</h3>
                                    <p className="text-gray-400">Keep in touch with the latest news and updates from Luday</p>
                                    
                                    <div className="flex relative mb-10 mt-5">
                                        <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                                            <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                                                </path>
                                            </svg>
                                        </span>
                                        <input type="text" id="email-with-icon" className=" flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" name="email" placeholder="Your email" {...register("email", { required: true, maxLength: 50 })}/>  
                                        
                                        <button className="rounded-r-md inline-flex  items-center px-3 border-t bg-white border-r border-b  border-gray-300 text-gray-500 shadow-sm text-sm" onClick={handleSubmit(submitForm)}>Send</button>
                                    </div>
                                    {errors.email && <small className="text-red-500 text-xs italic">Your e-mail is required</small>}
                                    {errors.email?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 100 </small></p>}
                                </div>
                            </form>
                      </div>
                  </div>
              </div>

              <hr className="h-px my-6 bg-gray-200 border-none dark:bg-gray-700"/>

              <div>
                  <p className="text-center text-gray-500 dark:text-gray-400">© Luday AB {new Date().getFullYear()} - All rights reserved</p>
              </div>
          </div>
      </footer>
    </>
  )
}

export default Footer
 