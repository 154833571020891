
 /* API routes */
export const CONTACT_API = process.env.REACT_APP_CONTACT_API; // eslint-disable-line
export const BLOGS_API = process.env.REACT_APP_BLOGS_API; // eslint-disable-line

/* public routes */
export const HOME = "/";
export const ABOUT = "/about";
export const INTERNSHIP = "/about/internship";
export const BLOG = "/blog";
export const BLOG_DETAILS = "/blog-details";
export const SERVICES = "/services";
export const PRIVACY_POLICY = "/privacy-policy";
export const SERVICE_DETAILS = "/service-details";
export const CONTACT = "/contact";
export const QUOTE = "/quote";
export const PORTFOLIO = "/portfolio";
export const NOTFOUND = "*";
export const AFFILIATE = "/affiliate";
export const EBIOREPOLICY = "/policy/app/ebiore";
 