
import React from "react";

const Changes = () => {
  return (
    <div className=" flex flex-col gap-2 mt-5" id="changes">
      <h2 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
        Changes to this Privacy Policy
      </h2>
      <p>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the "Last updated"
        date at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
    </div>
  );
};

export default Changes;


 