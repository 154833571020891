
import React from "react";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div className=" flex flex-col gap-2 mt-5" id="contact">
      <h2 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
        Contact Us
      </h2>
      <p>
        If you have any questions about this Privacy Policy, Kindly email us at{" "}
        {""}
        <a href="mailto:info@luday.se" className="text-[#3BA4E9] font-semibold">
          info@luday.se
        </a>{" "}
        or,
      </p>
      <Link to="/contact">
        <button className="bg-[#3BA4E9] flex gap-2 py-3 px-5 rounded-md text-white font-medium mt-4">
          Get in touch
        </button>
      </Link>
    </div>
  );
};

export default ContactUs;



 